import React from 'react';
import { Route, Routes } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';
import './App.css';
import ContactUs from './Pages/ContactUs/ContactUs';
import Nizar from './Pages/Nizar/Nizar';
import Yassine from './Pages/Yassine/Yassine';
import QrGenerator from './Pages/QR_Generator/QR_Generator';
import LaNFC from './Pages/LaNFC/LaNFC';
import ProSolutions from './Pages/ProSolutions/ProSolutions';
import FAQ_page from './Pages/FAQ_page/FAQ_page';
import CartePerso from './Pages/CartePerso/CartePerso';
import FluidesConcept from './Pages/FluidesConcept/FluidesConcept';
import MAB from './Pages/Olissey/MAB';
import NB from './Pages/Olissey/NB';
import Bilel from './Pages/Galata/Bilel';
import Hosni from './Pages/Galata/Hosni';
import Skander from './Pages/Skander/Skander';
import AouiniTarek from './Pages/Glamour/AouiniTarek';
import Ranime_Hassine from './Pages/Yassine/Ranime_Hassine';
import SCIT from './Pages/SCIT/SCIT';
import VcfForm from './Pages/VcfForm/VcfForm';
import MoniaFekih from './Pages/MoniaFekih/MoniaFekih';
import DaPietro_Resto from './Pages/DaPietro/DaPietro_Resto';
import DaPietro_Perso from './Pages/DaPietro/DaPietro_Perso';
import RamiMtimet from './Pages/RamiMtimet/RamiMtimet';
import Home from './Pages/Home/Home';
import Test from './Pages/Test/Test';
import HomeFinal from './Pages/HomeFinal/HomeFinal';
import Condition_generale from './Pages/Conditions_generales/Condition_generale';
import PolitiqueConfidentialite from './Pages/PolitiqueConfidentialite/PolitiqueConfidentialite';
import MentionsLegales from './Pages/MentionsLegales/MentionsLegales';
import DemarcheRSE from './Pages/DemarcheRSE/DemarcheRSE';
import Web_page from './Pages/Web_page/Web_page';
import Pvc_Carte from './Pages/Produits/Pvc_Carte';
import Majdi from './Pages/Nexus/Majdi';
import Mghaieth from './Pages/Nexus/Ridha_Mghaieth';
import CardEco from './Pages/Produits/CardEco';
import MetalGoldCarte from './Pages/Produits/MetalGoldCarte';
import MetalSilverCarte from './Pages/Produits/MetalSilverCarte';
import Card_Carbone from './Pages/Produits/Card_Carbone';
import Card_Google from './Pages/Produits/Card_google';
import TripAdvisor_card from './Pages/Produits/TripAdvisor_card';


function App() {
  return (
    <div className="App">
      <Helmet>
        <title>TechTide Solutions Tunisie</title>
        <meta name="description" content="TechTide Solutions - Encourage l'innovation en Tunisie. NFC Carte de visite virtuelle, Explorez des solutions technologiques de pointe, le développement de logiciels et des services innovants. Rejoignez-nous dans un voyage de créativité et d'avancement technologique." />
      </Helmet>
      <Routes>
        <Route index element={<HomeFinal/>} />
        <Route exact path='*' element={<HomeFinal/>} />
        <Route path='/contact_us' element={<ContactUs />} />
        <Route path='/FAQ' element={<FAQ_page/>} />
        <Route path='/LaNFC' element={<LaNFC/>} />
        <Route path='/VCF_Form' element={<VcfForm/>} />
        <Route path='/Pro' element={<ProSolutions/>} />
        <Route path='/web' element={<Web_page/>} />
        <Route path='/perso' element={<CartePerso/>} />
        <Route path='/QR_Generator' element={<QrGenerator />} />
        <Route path='/RSE' element={<DemarcheRSE />} />
        <Route path='/CGV' element={<Condition_generale />} />
        <Route path='/Politique' element={<PolitiqueConfidentialite />} />
        <Route path='/MentionsLegales' element={<MentionsLegales />} />
        <Route path='/PVC_carte' element={<Pvc_Carte/>} />
        <Route path='/ECO+_carte' element={<CardEco/>} />
        <Route path='/Metal_Gold' element={<MetalGoldCarte/>} />
        <Route path='/Metal_Silver' element={<MetalSilverCarte/>} />
        <Route path='/Carbone_Card' element={<Card_Carbone/>} />
        <Route path='/Google_Card' element={<Card_Google/>} />
        <Route path='/TripAdvisor_card' element={<TripAdvisor_card/>} />
        <Route path='/659acd9da2470bb816c7364e' element={<Yassine />} />
        <Route path='/658a1a79882255bb7b957abf' element={<Nizar />} />
        <Route path='/65d2857fb2b1c091482add3d' element={<MAB/>} />
        <Route path='/65d2859eb2b1c091482add40' element={<NB/>} />
        <Route path='/65d285b2b2b1c091482add42' element={<MAB/>} />
        <Route path='/65d2873e71953153efb64dde' element={<MAB/>} />
        <Route path='/65d39e0c7874b20b769b12b6' element={<FluidesConcept/>} />
        <Route path='/1f6e3d8b7c2a5b9e4d0f7a6c' element={<Bilel/>} />
        <Route path='/8c9f4a7d2b3e1a6b5c7d9e0f' element={<Hosni/>} />
        <Route path='/6601e099b7788cbe3a4e36c3' element={<Skander/>} />
        <Route path='/9e5a6d4b7c1f2e3a8b0d9f6c' element={<AouiniTarek/>} />
        <Route path='/7c4d9b1e5a3f8e2d6b0a7c4f' element={<Ranime_Hassine/>} />
        <Route path='/5d2f7a9c6e3b1d4a8f0c7e2b' element={<SCIT/>} />
        <Route path='/3e1b4a6d9c7f2b5d8a0e6c9f' element={<MoniaFekih/>} />
        <Route path='/7143c61ba7524d879de06c35' element={<DaPietro_Resto/>} />
        <Route path='/57cf6ae573a145b6b028ad7f' element={<DaPietro_Perso/>} />
        <Route path='/12aaf09703774a1eba0b5e49' element={<RamiMtimet/>} />
        <Route path='/12aaf0970377Nexus_call_center' element={<Majdi/>} />
        <Route path='/12aaf0970378Nexus_call_center' element={<Mghaieth/>} />
        <Route path='/test' element={<Test/>} />
      </Routes>
    </div>
  );
}

export default App;
