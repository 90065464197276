import React, { useEffect} from 'react';
import "./ContactUs.css"
import ContactForm from './ContactForm/ContactForm'
import ContactTop from './ContactTop/ContactTop'
import { Helmet } from 'react-helmet';
import FooterDesktop from '../Footer/FooterDesktop';
import NewNavbar from '../NewNavbar/NewNavbar';

const ContactUs = () => {
  useEffect(() => {
    // Scroll to top with smooth behavior when the component mounts
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className='ContactUs'>
<Helmet>
        <title>Nous-contacter - Techtide Solutions</title>
        <meta 
          name="description" 
          content="Vous avez une question ou un projet en tête ? Contactez Techtide Solutions, experts en développement web, solutions NFC, et services IT. Ensemble, réalisons vos ambitions !" 
        />
        <link rel="canonical" href="https://techtide-solutions.com/contact_us" />
      </Helmet>
    <NewNavbar/>
    <div className='ContactUs_Content'>
    <ContactTop/>
    <ContactForm/>
    </div>
    <FooterDesktop/>
  </div>
  )
}

export default ContactUs